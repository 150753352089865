<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Peocles Logo"
          class="shrink mr-2"
          contain
          src="https://media.tenor.com/tkpOfRTT21UAAAAi/flexed-biceps-joypixels.gif"
          transition="scale-transition"
          width="40"
        />
        <h1>Πεοκλής</h1>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main  class="gradient-background">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
html {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

html::-webkit-scrollbar {
  width: 0;
  height: 0;
}

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #b1b1b100;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(90, 90, 90);
    border-radius: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
/* .gradient-background {
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
} */
.gradient-background{
  background-image: linear-gradient(to bottom right, #ff11bc 0%, #4287ff 100%);
}
</style>
