<template>
  <v-container>
    <v-card class="px-2 my-6" rounded="xl">
      <v-row>
        <v-col cols="3" class="pr-0">
          <v-text-field
            v-model="author"
            label="Όνομα"
            class="pl-2 my-0"
            hide-details
            outlined
          />
        </v-col>
        <v-col cols="7">
          <v-text-field
            v-model="subject"
            label="Τίτλος"
            class="my-0"
            hide-details
            outlined
          />
        </v-col>
        <v-col cols="2" class="d-flex justify-center py-0 my-auto">
          <v-btn
            color="primary"
            @click="addPost"
            block
            class="pa-5 mr-5"
            v-if="!postLoading"
          >
            Post
          </v-btn>
          <div v-else class="spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-textarea
            v-model="message"
            label="Μήνυμα"
            class="px-2 mt-0 mb-3"
            rows="2"
            outlined
            hide-details
          />
        </v-col>
      </v-row>
    </v-card>
    <div v-if="!loading" class="d-flex flex-wrap flex-column">
      <v-card
        v-for="post in posts"
        :key="post.id"
        rounded="xl"
        class="my-2 text-center mx-auto"
      >
        <v-card-title class="justify-center pb-0">{{
          post.subject
        }}</v-card-title>
        <v-card-subtitle class="justify-center pt-3"
          >{{ relativeDate(post.timestamp) }} από
          {{ post.author }}</v-card-subtitle
        >
        <v-card-text class="font-weight-medium black--text">{{
          post.message
        }}</v-card-text>
      </v-card>
    </div>
    <div v-else>
      <!-- align in the center of the page in the middle -->
      <v-card
        tile
        class="pa-2 d-flex justify-center align-center"
        color="transparent"
        elevation="0"
      >
        <v-card-text class="text-center">
          <v-row class="text-center mt-10 mx-auto justify-center align-center">
            <v-col cols="12">
              <div class="🤚 d-flex mt-10 mx-auto">
                <div class="👉"></div>
                <div class="👉"></div>
                <div class="👉"></div>
                <div class="👉"></div>
                <div class="🌴"></div>
                <div class="👍"></div>
              </div>
            </v-col>

            <v-col class="mb-4">
              <h1 class="display-4 font-weight-bold mb-3">
                {{ msg }}
              </h1>

              <p class="subheading font-weight-regular">
                Περίμενε μέχρι να φορτώσει.
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
  onSnapshot,
  getDocs,
  addDoc,
  collection,
} from "firebase/firestore";
import moment from "moment";
moment.locale("el");

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBJsRiq7ANT2TbmdogavLTNKlY3sIbiZnU",
  authDomain: "peocles-2d120.firebaseapp.com",
  projectId: "peocles-2d120",
  storageBucket: "peocles-2d120.appspot.com",
  messagingSenderId: "115835469212",
  appId: "1:115835469212:web:faeac543f28614c51adc59",
  measurementId: "G-XKFYL3KKTB",
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
const db = getFirestore(firebase);

const sortPosts = (posts) => {
  return posts.sort((a, b) => new Date(b.id) - new Date(a.id));
};
export default {
  name: "Welcome",

  props: {
    msg: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    sound: new Audio(require("@/assets/hangover-sound.mp3")),
    posts: null,
    subject: "",
    message: "",
    author: "",
    loading: false,
    postLoading: false,
    prevLength: 0,
  }),
  mounted() {
    if ("Notification" in window && Notification.permission !== "granted") {
      Notification.requestPermission();
      this.sound.volume = 0.5;
    }
    this.loading = true;
    const postsCollection = collection(db, "posts");
    getDocs(postsCollection)
      .then((querySnapshot) => {
        const posts = [];
        querySnapshot.forEach((doc) => {
          posts.push({ id: doc.id, ...doc.data() });
        });
        this.posts = sortPosts(posts);
        this.prevLength = posts.length;
      })
      .catch((error) => {
        console.error("Error fetching posts: ", error);
      })
      .finally(() => {
        this.loading = false;
      });
    // Listen for real-time updates to posts collection
    onSnapshot(postsCollection, (snapshot) => {
      const posts = [];
      snapshot.forEach((doc) => {
        posts.push({ id: doc.id, ...doc.data() });
      });
      this.posts = sortPosts(posts);
      if (this.prevLength < posts.length) {
        this.prevLength = posts.length;

        // Trigger notification
        if (
          "Notification" in window &&
          Notification.permission === "granted" &&
          document.hidden
        ) {
          const notification = new Notification("Νέο Πόστ", {
            body: "Κάποιος Πεοκλής πόσταρε. Μπές να το τσεκάρεις.",
          });
          this.sound.play();
        }
      }
    });
  },
  methods: {
    relativeDate(date) {
      return moment(date).fromNow();
    },
    addPost() {
      if (
        this.subject.length < 2 ||
        this.subject.length > 50 ||
        this.message.length < 5 ||
        this.message.length > 500
      ) {
        alert("Please enter a valid subject and message.");
        return;
      }
      this.postLoading = true;

      // Get current date
      const currentDate = new Date().toISOString().slice(0, 10); // YYYY-MM-DD

      // Get user posts from local storage
      let userPosts = JSON.parse(localStorage.getItem("userPosts")) || {};

      // Check if the user has already posted today
      if (!userPosts[currentDate]) {
        userPosts[currentDate] = 1; // Initialize count for today
      } else {
        // Check if user has reached the maximum post limit for today
        if (userPosts[currentDate] >= 5) {
          alert("You have reached the maximum post limit for today.");
          this.postLoading = false;
          return;
        }
        userPosts[currentDate]++; // Increment count for today
      }

      // Save updated userPosts to local storage
      localStorage.setItem("userPosts", JSON.stringify(userPosts));
      // get user's ip
      fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((data) => {
          addDoc(collection(db, "posts"), {
            id: this.posts.length + 1,
            subject: this.subject,
            message: this.message,
            timestamp: new Date().toISOString(),
            author: this.author || "Ανώνυμος Πεοκλής",
            ip: data.ip,
          })
            .then(() => {
              console.log("Post added successfully!");
              this.subject = "";
              this.message = "";
              this.postLoading = false;
            })
            .catch((error) => {
              console.error("Error adding post: ", error);
            });
        });
      // Add post to Firestore
    },
  },
};
</script>

<style>
.🤚 {
  --skin-color: #e4c560;
  --tap-speed: 0.6s;
  --tap-stagger: 0.1s;
  position: relative;
  width: 80px;
  height: 60px;
  margin-left: 80px;
}

.🤚:before {
  content: "";
  display: block;
  width: 180%;
  height: 75%;
  position: absolute;
  top: 70%;
  right: 20%;
  background-color: black;
  border-radius: 40px 10px;
  filter: blur(10px);
  opacity: 0.3;
}

.🌴 {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--skin-color);
  border-radius: 10px 40px;
}

.👍 {
  position: absolute;
  width: 120%;
  height: 38px;
  background-color: var(--skin-color);
  bottom: -18%;
  right: 1%;
  transform-origin: calc(100% - 20px) 20px;
  transform: rotate(-20deg);
  border-radius: 30px 20px 20px 10px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid rgba(0, 0, 0, 0.1);
}

.👍:after {
  width: 20%;
  height: 60%;
  content: "";
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  bottom: -8%;
  left: 5px;
  border-radius: 60% 10% 10% 30%;
  border-right: 2px solid rgba(0, 0, 0, 0.05);
}

.👉 {
  position: absolute;
  width: 80%;
  height: 35px;
  background-color: var(--skin-color);
  bottom: 32%;
  right: 64%;
  transform-origin: 100% 20px;
  animation-duration: calc(var(--tap-speed) * 2);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  transform: rotate(10deg);
}

.👉:before {
  content: "";
  position: absolute;
  width: 140%;
  height: 30px;
  background-color: var(--skin-color);
  bottom: 8%;
  right: 65%;
  transform-origin: calc(100% - 20px) 20px;
  transform: rotate(-60deg);
  border-radius: 20px;
}

.👉:nth-child(1) {
  animation-delay: 0;
  filter: brightness(70%);
  animation-name: tap-upper-1;
}

.👉:nth-child(2) {
  animation-delay: var(--tap-stagger);
  filter: brightness(80%);
  animation-name: tap-upper-2;
}

.👉:nth-child(3) {
  animation-delay: calc(var(--tap-stagger) * 2);
  filter: brightness(90%);
  animation-name: tap-upper-3;
}

.👉:nth-child(4) {
  animation-delay: calc(var(--tap-stagger) * 3);
  filter: brightness(100%);
  animation-name: tap-upper-4;
}

@keyframes tap-upper-1 {
  0%,
  50%,
  100% {
    transform: rotate(10deg) scale(0.4);
  }

  40% {
    transform: rotate(50deg) scale(0.4);
  }
}

@keyframes tap-upper-2 {
  0%,
  50%,
  100% {
    transform: rotate(10deg) scale(0.6);
  }

  40% {
    transform: rotate(50deg) scale(0.6);
  }
}

@keyframes tap-upper-3 {
  0%,
  50%,
  100% {
    transform: rotate(10deg) scale(0.8);
  }

  40% {
    transform: rotate(50deg) scale(0.8);
  }
}

@keyframes tap-upper-4 {
  0%,
  50%,
  100% {
    transform: rotate(10deg) scale(1);
  }

  40% {
    transform: rotate(50deg) scale(1);
  }
}

.spinner {
  width: 44px;
  height: 44px;
  animation: spinner-y0fdc1 2s infinite ease;
  transform-style: preserve-3d;
}

.spinner > div {
  background-color: rgba(0, 77, 255, 0.2);
  height: 100%;
  position: absolute;
  width: 100%;
  border: 2px solid #004dff;
}

.spinner div:nth-of-type(1) {
  transform: translateZ(-22px) rotateY(180deg);
}

.spinner div:nth-of-type(2) {
  transform: rotateY(-270deg) translateX(50%);
  transform-origin: top right;
}

.spinner div:nth-of-type(3) {
  transform: rotateY(270deg) translateX(-50%);
  transform-origin: center left;
}

.spinner div:nth-of-type(4) {
  transform: rotateX(90deg) translateY(-50%);
  transform-origin: top center;
}

.spinner div:nth-of-type(5) {
  transform: rotateX(-90deg) translateY(50%);
  transform-origin: bottom center;
}

.spinner div:nth-of-type(6) {
  transform: translateZ(22px);
}

@keyframes spinner-y0fdc1 {
  0% {
    transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
  }

  50% {
    transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
  }

  100% {
    transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
  }
}
</style>